<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select
        default-value="2"
        :value="value"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option
          v-for="item in arrDocTypes"
          :value="item.id"
          :key="item.id"
          >{{ item.codeName }}</a-select-option
        >
      </a-select>
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ filterDocType(value) }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableselectCell",
  props: {
    text: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      value: this.text,
      editable: false,

      arrDocTypes: [],
      objDocType: {},
    };
  },
  created() {
    this.getDocType();
  },
  methods: {
    getDocType() {
      this.arrDocTypes = JSON.parse(localStorage.getItem("arrDocTypes"));

      this.objDocType = this.objDocType = JSON.parse(
        localStorage.getItem("objDocType")
      );
    },
    filterDocType(val) {
      return this.objDocType[val] || "";
    },
    handleChange(e) {
      console.log("handleChange-sel", e);
      const value = e;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 26px;
  // display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
